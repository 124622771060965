import { FintechCustomerDTO } from '../../models/customer';
import { FintechEventDTO } from '../../models/event';
import { v4 as uuidv4 } from 'uuid';
import { FintechAccountDTO } from '../../models/account';
import { FintechTaxEntityDTO } from '../../models/tax-entity';
import { PlaidInstitutionAuth } from '../../models';
import { GoalModel } from 'dal/models/goal';
import { EmployerModel } from 'dal/models/employer';

/**
 * `InMemoryStorage` is used to copy mongo `db` ref, where we map between collection name and the data.
 * This way we can share the underlying storage layer for all instances of DataStore, so it does not matter
 * how many new instances of `Platform` there will be during UT, they will all share the same storage.
 */
type InMemoryStorage = {
    _objectId: string;
    customers: Record<string, FintechCustomerDTO>;
    accounts: Record<string, FintechAccountDTO>;
    events: Record<string, FintechEventDTO>;
    taxEntities: Record<string, FintechTaxEntityDTO>;
    plaidInstitutionAuths: Record<string, PlaidInstitutionAuth>;
    goals: Record<string, GoalModel>;
    employers: Record<string, EmployerModel>;
};

let inMemoryStore = (global as unknown as { __inMemoryStore: InMemoryStorage }).__inMemoryStore;
if (!inMemoryStore) {
    inMemoryStore = {
        _objectId: uuidv4(),
        customers: {},
        accounts: {},
        events: {},
        taxEntities: {},
        plaidInstitutionAuths: {},
        goals: {},
        employers: {}
    };
    (global as unknown as { __inMemoryStore: InMemoryStorage }).__inMemoryStore = inMemoryStore;
}
export default inMemoryStore as InMemoryStorage;
