import Image, { ImageProps } from 'next/image';

interface Props extends Omit<ImageProps, 'alt'> {
    className?: string;
    'data-testid'?: string;
    alt?: string;
}

const ImageWrapper = (props: Props) => {
    return (
        <span className={props.className} style={{ height: props.height, width: props.width }}>
            <Image {...props} alt={props.alt || ''} />
        </span>
    );
};

export default ImageWrapper;
