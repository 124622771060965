import { useEffect, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';

enum SideNavStates {
    off = 'off',
    pinned = 'pinned',
    collapsed = 'collapsed'
}

const MESSAGE_EVENT = 'hb-sidenav-toggle';

export const useSideNavSupport = () => {
    const [sidenavState, setSidenavState] = useState(() => {
        const params = new URLSearchParams(global?.location?.search);
        return params.get('sidenav') || SideNavStates.off;
    });

    useEffect(() => {
        function onMessage(e: MessageEvent) {
            if (e.data.event === MESSAGE_EVENT) {
                setSidenavState(e.data.payload.pinned ? SideNavStates.pinned : SideNavStates.collapsed);
            }
        }
        window.addEventListener('message', onMessage);
        return () => {
            window.removeEventListener('message', onMessage);
        };
    }, [setSidenavState]);

    useEffect(() => {
        document.body.setAttribute('sidenav-state', sidenavState);
    }, [sidenavState]);

    useEffectOnce(() => {
        window.parent.postMessage({ event: `${MESSAGE_EVENT}:get-state` }, '*');
    });
};
