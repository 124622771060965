import { noop } from 'lodash';
import platformConfig from '../config/platformConfig';
import { CustomerInMemoryDataService } from './service/inmemory/customer-in-memory-data-service';
import { CustomerMongoDataService } from './service/mongo/customer-mongo-data-service';
import { CustomerDataService } from '../service/customer-data-service';
import { logger } from '@finance/shared';
import inMemoryStore from './service/inmemory/in-memory-store';
import { Db, MongoClient } from 'mongodb';
import { EventDataService } from '../service/event-data-service';
import { EventMongoDataService } from './service/mongo/event-mongo-data-service';
import { EventInMemoryDataService } from './service/inmemory/event-in-memory-data-service';
import AccountApi from '../api/unit/account.api';
import CustomerApi from '../api/unit/customer.api';
import ApplicationApi from '../api/unit/application.api';
import { ApiContext } from '../types';
import { AccountDataService } from '../service/account-data-service';
import { AccountMongoDataService } from './service/mongo/account-mongo-data-service';
import { AccountInMemoryDataService } from './service/inmemory/account-in-memory-data-service';
import { TaxEntityDataService } from '../service/tax-entity-data-service';
import { TaxEntityMongoDataService } from './service/mongo/tax-entity-mongo-data-service';
import { TaxEntityInMemoryDataService } from './service/inmemory/tax-entity-in-memory-data-service';
import { PlaidInstitutionAuthDataService } from '../service/plaid-institution-auth-data-service';
import { PlaidInstitutionAuthMongoDataService } from './service/mongo/plaid-institution-auth-mongo-data-service';
import { PlaidInstitutionAuthInMemoryDataService } from './service/inmemory/plaid-institution-auth-in-memory-data-service';
import { GoalDataService } from '../service/goal-data-service';
import { GoalMongoDataService } from './service/mongo/goal-mongo-data-service';
import { GoalInMemoryDataService } from './service/inmemory/goal-in-memory-data-service';
import { EmployerDataService } from '../service/employer-data-service';
import { EmployerMongoDataService } from './service/mongo/employer-mongo-data-service';
import { EmployerInMemoryDataService } from './service/inmemory/employer-in-memory-data-service';

let instance: DataStore;

export const newDataStore = (context?: ApiContext) => {
    let dataStore: DataStore;
    if (platformConfig.database.enabled) {
        dataStore = new MongoDataStore(context);
    } else {
        dataStore = new InMemoryDataStore(context);
    }
    return dataStore;
};

const initDataStore = () => {
    instance = newDataStore();
    logger.info(`DataStore instance created with ${platformConfig.database.enabled ? 'DB' : 'InMemoryDB'} implementation`);
};

export const getDataStore = (): DataStore => {
    if (!instance) {
        initDataStore();
    }

    return instance;
};

/** For UT only! used to reset the singleton instance */
export const resetDataStore = async (): Promise<void> => {
    if (process.env.NODE_ENV === 'test') {
        await instance?.close();
        initDataStore();
    }
};

export abstract class DataStore {
    protected _customerDataService: CustomerDataService | undefined;
    protected _accountDataService: AccountDataService | undefined;
    protected _eventDataService: EventDataService | undefined;
    protected _taxEntityDataService: TaxEntityDataService | undefined;
    protected _plaidInstitutionAuthDataService: PlaidInstitutionAuthDataService | undefined;
    protected _goalDataService: GoalDataService | undefined;
    protected _employerDataService: EmployerDataService | undefined;

    constructor(context?: ApiContext) {
        this.initServices(context);
    }

    async close(): Promise<void> {
        noop();
    }

    set context(context: ApiContext) {
        this.customerDataService.context = context;
        this.accountDataService.context = context;
    }

    protected abstract initServices(context?: ApiContext): void;

    get customerDataService() {
        return <CustomerDataService>this._customerDataService;
    }

    get accountDataService() {
        return <AccountDataService>this._accountDataService;
    }

    get eventDataService() {
        return <EventDataService>this._eventDataService;
    }

    get taxEntityDataService() {
        return <TaxEntityDataService>this._taxEntityDataService;
    }

    get plaidInstitutionAuthDataService() {
        return <PlaidInstitutionAuthDataService>this._plaidInstitutionAuthDataService;
    }

    get goalDataService() {
        return <GoalDataService>this._goalDataService;
    }

    get employerDataService() {
        return <EmployerDataService>this._employerDataService;
    }
}

export class MongoDataStore extends DataStore {
    private db: Db | undefined;

    protected initServices(context?: ApiContext) {
        const typedGlobal = global as unknown as { __mongo: MongoClient };
        this.db = typedGlobal.__mongo.db(platformConfig.database.dbName);
        const customerDataService = new CustomerMongoDataService(this.db);
        const accountDataService = new AccountMongoDataService(this.db);
        const eventDataService = new EventMongoDataService(this.db);
        const taxEntityDataService = new TaxEntityMongoDataService(this.db);
        const plaidInstitutionAuthDataService = new PlaidInstitutionAuthMongoDataService(this.db);
        const goalDataService = new GoalMongoDataService(this.db);
        const employerDataService = new EmployerMongoDataService(this.db);
        const axiosFactoryProps = { cacheAxios: false };
        this._customerDataService = new CustomerDataService({
            dataService: customerDataService,
            ...(context
                ? {
                      unitAccountApi: new AccountApi(context, axiosFactoryProps),
                      unitCustomerApi: new CustomerApi(context, axiosFactoryProps),
                      unitApplicationApi: new ApplicationApi(context, axiosFactoryProps)
                  }
                : {})
        });
        this._accountDataService = new AccountDataService({
            dataService: accountDataService,
            ...(context
                ? {
                      unitAccountApi: new AccountApi(context, axiosFactoryProps)
                  }
                : {})
        });
        this._eventDataService = new EventDataService({
            dataService: eventDataService
        });
        this._taxEntityDataService = new TaxEntityDataService({
            dataService: taxEntityDataService
        });
        this._plaidInstitutionAuthDataService = new PlaidInstitutionAuthDataService({
            dataService: plaidInstitutionAuthDataService
        });
        this._goalDataService = new GoalDataService({
            dataService: goalDataService
        });
        this._employerDataService = new EmployerDataService({
            dataService: employerDataService
        });
    }

    async close() {
        // If there is a previous reference (Unit tests) close it. We bypass TS because mongodb does not have Db.close, but mongo-mock does.
        // For more info as to why we do this, see jest.setup.js:afterAll
        await (this.db as unknown as { close: () => Promise<void> })?.close?.();
        const typedGlobal = global as unknown as { __mongo: MongoClient };
        await typedGlobal.__mongo.close();
    }
}

export class InMemoryDataStore extends DataStore {
    protected initServices(context?: ApiContext) {
        logger.debug({ inMemoryStore }, 'InMemoryDB: initServices');
        const customerDataService = new CustomerInMemoryDataService(inMemoryStore.customers);
        const accountDataService = new AccountInMemoryDataService(inMemoryStore.accounts);
        const eventDataService = new EventInMemoryDataService(inMemoryStore.events);
        const taxEntityDataService = new TaxEntityInMemoryDataService(inMemoryStore.taxEntities);
        const goalDataService = new GoalInMemoryDataService(inMemoryStore.goals);
        const plaidInstitutionAuthDataService = new PlaidInstitutionAuthInMemoryDataService(inMemoryStore.plaidInstitutionAuths);
        const employerDataService = new EmployerInMemoryDataService(inMemoryStore.employers);
        const axiosFactoryProps = { cacheAxios: false };
        this._customerDataService = new CustomerDataService({
            dataService: customerDataService,
            ...(context
                ? {
                      unitAccountApi: new AccountApi(context, axiosFactoryProps),
                      unitCustomerApi: new CustomerApi(context, axiosFactoryProps),
                      unitApplicationApi: new ApplicationApi(context, axiosFactoryProps)
                  }
                : {})
        });
        this._accountDataService = new AccountDataService({
            dataService: accountDataService,
            ...(context
                ? {
                      unitAccountApi: new AccountApi(context, axiosFactoryProps)
                  }
                : {})
        });
        this._eventDataService = new EventDataService({
            dataService: eventDataService
        });
        this._taxEntityDataService = new TaxEntityDataService({
            dataService: taxEntityDataService
        });
        this._plaidInstitutionAuthDataService = new PlaidInstitutionAuthDataService({
            dataService: plaidInstitutionAuthDataService
        });
        this._goalDataService = new GoalDataService({
            dataService: goalDataService
        });
        this._employerDataService = new EmployerDataService({
            dataService: employerDataService
        });
    }
}
