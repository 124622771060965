import logger from '../utils/logger';

export class ContextHeaderMismatchError extends Error {
    constructor(
        headerKey: 'x-hb-request-company-id' | 'x-hb-request-account-id',
        headerValue: string,
        checkedValues: string[]
    ) {
        super('Flitter returned a different correlation header than exists in request');

        logger.error(
            {
                headerKey,
                headerValue,
                checkedValues
            },
            this.message
        );
    }
}
