import { signOut } from 'next-auth/react';
import { appWithTranslation, useTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import ErrorBoundary from 'components/ErrorBoundary';
import DatadogRumInit from 'lib/client/datadogRum';
import { LocaleNS } from 'lib/client/locale-ns';
import RouteListener from 'lib/client/RouteListener';
import { GlobalStyles } from 'styles/globals';
import { useEffect } from 'react';
import { signOutMessage } from 'lib/client/services/postMessageService';
import { TestIdBoundaryProvider } from '@honeybook/hbui';
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Tooltip } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { chartBase } from 'components/common/charts/chartBase';
import GlobalProviders from 'providers/GlobalProviders';
import { useSideNavSupport } from '../components/SideNavSupport/useSideNavSupport';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend, PointElement, LineElement, annotationPlugin, ArcElement);
chartBase(ChartJS);

const publicPaths = ['/auth/token', '/auth/embedded', '/terms/[doc_name]', '/403'];

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
    const { t } = useTranslation(['common']);
    const { pathname } = useRouter();

    useEffect(() => {
        if (session?.error === 'RefreshAccessTokenError') {
            signOutMessage();
            signOut();
        }
    }, [session]);

    useSideNavSupport();

    if (publicPaths.includes(pathname)) {
        return (
            <>
                <ErrorBoundary>
                    <TestIdBoundaryProvider prefix={'finance'}>
                        <GlobalStyles />
                        <RouteListener />
                        <DatadogRumInit />
                        <Component {...pageProps} />
                    </TestIdBoundaryProvider>
                </ErrorBoundary>
            </>
        );
    }

    return (
        <>
            <Head>
                <title>{t('top-bar-title', { ns: LocaleNS.common })}</title>
            </Head>
            <GlobalProviders session={session}>
                <Component {...pageProps} />
            </GlobalProviders>
        </>
    );
}

export default appWithTranslation(MyApp);
