import { Db, WithId } from 'mongodb';
import { AbstractMongoDataService } from './abstract-mongo-data-service';
import { FintechEmployerDataService } from '../../../types/dal-types';
import { MongoCollectionsEnum } from '../../../types';
import { EmployerModel } from '../../models/employer';
import { ObjectId } from 'bson';

export class EmployerMongoDataService extends AbstractMongoDataService<EmployerModel> implements FintechEmployerDataService<EmployerModel> {
    constructor(db: Db) {
        super(db);
    }

    getCollectionName(): MongoCollectionsEnum {
        return MongoCollectionsEnum.EMPLOYER;
    }

    async findByHbUserId(hbUserId: string): Promise<WithId<EmployerModel> | null> {
        return this.collection.findOne({ hbUserId: new ObjectId(hbUserId) });
    }
}
