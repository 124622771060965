import mixpanel from 'mixpanel-browser';
import { useSession } from 'next-auth/react';
import getConfig from 'next/config';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isProductionEnv } from 'lib/client/utils';
import logger from '@finance/shared/dist/utils/logger';
import { useSegment } from 'providers/SegmentProvider';
import useFullStory from 'lib/analytics/hooks/useFullStory';

export interface IAnalyticsEventProperties {
    source?: string;
    type?: string;
    authenticated?: boolean;
    reason?: string;
    status?: string;
    step?: string;
    version?: string;
    variant?: string;
    watched?: string;
    context?: object;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

export interface ITrackEvents {
    eventName: string;
    eventType: AnalyticsEventTypes;
    properties?: IAnalyticsEventProperties;
}

export type AnalyticsEventTypes = 'click' | 'success' | 'load' | 'server' | 'error' | 'pageview' | 'submit';

export default function useAnalytics() {
    const { trackFullstoryEvent } = useFullStory();
    const { track: trackSegmentEvent } = useSegment();
    const {
        publicRuntimeConfig: { mixpanelToken, env }
    } = getConfig();

    const shouldTrackEvents = useMemo(() => isProductionEnv() && mixpanelToken, [mixpanelToken]);

    const session = useSession();
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (shouldTrackEvents && !isLoaded) {
            mixpanel.init(mixpanelToken, {
                loaded: () => {
                    logger.info('mixpanel loaded successfully');
                    setIsLoaded(true);
                }
            });
        }
    }, [shouldTrackEvents, mixpanelToken, isLoaded]);

    useEffect(() => {
        if (isLoaded && session.data) {
            logger.debug({ session }, 'identifying mixpanel user');
            mixpanel.identify(session.data.user._id);
            mixpanel.people.set('unitCustomerId', session.data.customerId);
            mixpanel.people.set('email', session.data.user.email);
            mixpanel.register({
                platform: 'Finance app',
                environment: env
            });
        }
    }, [isLoaded, session, env]);

    const logEvent = useCallback(({ eventName, eventType, properties }: ITrackEvents) => {
        logger.info({ eventName: `${eventType}: ${eventName}`, properties }, 'Analytics event');
    }, []);

    const trackEvent = ({ eventName, eventType, properties }: ITrackEvents) => {
        logEvent({ eventName, eventType, properties });
        eventName = `${eventType}: ${eventName}`;
        if (mixpanel.get_distinct_id()) {
            mixpanel.track(eventName, {
                ...properties
            });
            trackFullstoryEvent({
                event: eventName,
                type: eventType,
                props: properties
            });
            trackSegmentEvent(eventName, properties);
        } else {
            logger.error({ eventName }, 'error tracking event, mixpanel was not loaded');
        }
    };

    return {
        track: shouldTrackEvents ? trackEvent : logEvent
    };
}
