import { colors, mediaQueries } from '@honeybook/hbui';
import { createGlobalStyle } from 'styled-components';
import { fontFaces } from 'styles/fonts';
import getConfig from 'next/config';
import 'react-datepicker/dist/react-datepicker.css';

export const GlobalStyles = createGlobalStyle`
  ${fontFaces}
  :root {
    --font-body: 'ProximaNova';
    --mobile-gutters: 8px;
    --mobile-bar-height: 60px;
    --topbar-height: ${getConfig().publicRuntimeConfig.hasStickyHeader ? '0px' : '50px'};
    --default-padding: 16px;
    --top-buffer: ${getConfig().publicRuntimeConfig.hasStickyHeader ? '0px' : 'calc(100px + 72px)'};
    --sidenav-width: 0;
  }

  #__next {
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  html,
  body {
    margin: 0;
    height: 100%;
    width: 100%;
    font-family: var(--font-body);
    color: ${colors.neutral900};
  }

  body {
    margin-block-start: var(--topbar-height);
    width: calc(100% - var(--sidenav-width));
  }
  
  body.no-scroll {
    overflow: hidden;
  }
  
  body[sidenav-state='collapsed'], body[sidenav-state='collapsed'][data-scroll-locked] {
    --sidenav-width: 56px;
    margin-inline-start: var(--sidenav-width);
    
    // override body-scroll-lock padding from hbui
    padding-left:0;
  }


  body[sidenav-state='pinned'], body[sidenav-state='pinned'][data-scroll-locked] {
    --sidenav-width: 240px;
    margin-inline-start: var(--sidenav-width);
    
    // override body-scroll-lock padding from hbui
    padding-left:0;
  }

  ${mediaQueries.mediumUp} {
    body {
      margin-block-start: var(--top-buffer);
      scroll-padding-bottom: 80px;
    }
  }

  body:has([role='dialog']) {
    overflow: hidden;
  }

  body:has(.fullscreen) {
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;

    &:focus {
      outline: none;
    }
  }

  button {
    font-family: var(--font-body);
    border:0;
  }
`;
