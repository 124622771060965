import { AbstractInMemoryDataService } from './abstract-in-memory-data-service';
import { FintechDataService, WithId } from '../../../types/dal-types';
import logger from '@finance/shared/dist/utils/logger';
import { EmployerModel } from '../../models/employer';
import _ from 'lodash';

export class EmployerInMemoryDataService extends AbstractInMemoryDataService<EmployerModel> implements FintechDataService<EmployerModel> {
    public constructor(storage: Record<string, EmployerModel>) {
        super(storage);
        logger.debug({ storage }, 'InMemoryDB: EmployerInMemoryDataService.new');
    }

    async findByHbUserId(hbUserId: string): Promise<WithId<EmployerModel> | null> {
        const employer = _.find(this.storage, employer => employer.hbUserId.toHexString() === hbUserId);
        return employer ? <WithId<EmployerModel>>employer : null;
    }
}
