type LogLevel = 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace';

const env = {
    isCodeFreshEnv: process.env.IS_CODEFRESH_ENV || false,
    isDevelopmentEnv: process.env.NODE_ENV === 'development' || process.env.FINANCE_ENV === 'development',
    isProductionEnv: process.env.NODE_ENV === 'production' && process.env.FINANCE_ENV === 'production',
    isTestEnv: process.env.NODE_ENV === 'test' || process.env.FINANCE_ENV === 'test',
    nodeEnv: process.env.NODE_ENV || 'development'
};

const kafka = {
    broker: process.env.KAFKA_BROKER || '',
    clientId: process.env.KAFKA_CLIENT_ID,
    caLocation: process.env.KAFKA_CA_LOCATION,
    username: process.env.KAFKA_USERNAME || '',
    password: process.env.KAFKA_PASSWORD || '',
    enabled: Boolean((process.env.KAFKA_ENABLED || 'true').toLowerCase() === 'true'),
    initialRetryTime: Number(process.env.KAFKA_INITIAL_RETRY_TIME_MS) || 100,
    retries: Number(process.env.KAFKA_RETRIES || 3),
    maxRetryTime: Number(process.env.KAFKA_MAX_RETRY_TIME_MS) || 180000,
    logLevel: process.env.KAFKAJS_LOG_LEVEL || 'NOTHING'
};

const postmark = {
    token: process.env.POSTMARK_SERVER_TOKEN || ''
};

const slack = {
    botToken: String(process.env.SLACK_BOT_TOKEN),
    appToken: String(process.env.SLACK_APP_TOKEN)
};

const twilio = {
    accountSid: process.env.TWILIO_ACCOUNT_SID || '',
    token: process.env.TWILIO_TOKEN || '',
    serviceSid: process.env.TWILIO_SERVICE_SID || ''
};

const segment = {
    writeKey: process.env.SEGMENT_WRITE_KEY
};

const dataSourceCacheTTL = Number(process.env.DATA_SOURCE_CACHE_TTL_SECONDS || 15) * 60 * 1000;
const logLevel: LogLevel = (process.env.LOG_LEVEL as LogLevel) || (env.isDevelopmentEnv ? 'info' : 'debug');

export const sharedConfig = {
    dataSourceCacheTTL,
    env,
    kafka,
    logLevel,
    postmark,
    slack,
    twilio,
    segment
};

export default sharedConfig;
