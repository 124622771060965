import { AsyncLocalStorage } from 'async_hooks';
import { Logger } from 'pino';

export type AlxContextStore = {
    logger: Logger;
    requestId: string;

    ctx: {
        ctxc?: string; // companyId
        ctxac?: string; // auditeeCompanyId
        ctxa?: string; // accountId
        ctxu?: string; // userId
    };
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const alsContext = global.__alsContext as AsyncLocalStorage<AlxContextStore>; // eslint-disable-line @typescript-eslint/no-explicit-any
export default alsContext;
