import { HBModal } from '@honeybook/hbui';
import { createContext, ReactElement, ReactNode, useCallback, useContext, useState } from 'react';
import ClientOnly from 'components/ClientOnly';
import { modalInteractionMessage } from 'lib/client/services/postMessageService';

interface IContextProps {
    openModal: (content: ReactElement<typeof HBModal>) => void;
    closeModal: () => void;
    isOpen: boolean;
}

export const ModalContext = createContext<IContextProps>({} as IContextProps);
ModalContext.displayName = 'ModalContext';

export const useModal = () => {
    return useContext(ModalContext);
};

type IModalContent = ReactElement<typeof HBModal> | null;

const ModalProvider = ({ children, testMode }: { children: ReactNode; testMode?: boolean }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState<IModalContent>();

    const openModal = useCallback(
        (content: ReactElement<typeof HBModal>) => {
            modalInteractionMessage({ isOpen: true });
            if (!testMode) {
                window.scrollTo(0, 0);
            }
            setModalContent(content);
            setIsOpen(true);
        },
        [setIsOpen, setModalContent, testMode]
    );

    const closeModal = useCallback(() => {
        modalInteractionMessage({ isOpen: false });
        setIsOpen(false);
        setModalContent(null);
    }, [setIsOpen, setModalContent]);

    const value = {
        openModal,
        closeModal,
        isOpen
    };
    return (
        <ModalContext.Provider value={value}>
            <ClientOnly>{modalContent}</ClientOnly>
            {children}
        </ModalContext.Provider>
    );
};

export default ModalProvider;
