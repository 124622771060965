import { DataStore, newDataStore } from './dal/data-store';
import { ApiContext } from './types';

export class Platform {
    public readonly dataStore: DataStore;

    constructor(context?: ApiContext) {
        this.dataStore = newDataStore(context);
    }

    set context(context: ApiContext) {
        this.dataStore.context = context;
    }
}

// Public DAL - Access to our data services is done through DataStore.
export { DataStore } from './dal/data-store';
export { CustomerDataService } from './service/customer-data-service';
export { EventDataService } from './service/event-data-service';
export {
    Transaction as PlaidTransaction,
    RemovedTransaction as PlaidRemovedTransaction,
    TransactionCode as PlaidTransactionCode,
    TransactionPaymentChannelEnum as PlaidTransactionPaymentChannelEnum,
    AccountsGetResponse as PlaidAccountsGetResponse,
    AccountBase as PlaidAccountBase,
    AccountSubtype as PlaidAccountSubtype,
    AccountType as PlaidAccountType,
    AccountBalance as PlaidAccountBalance,
    Item as PlaidItem,
    Products as PlaidProducts,
    ItemUpdateTypeEnum as PlaidItemUpdateTypeEnum
} from 'plaid';

// Public types & enums
export * from './types';
export * from './errors';
export * from './dal/models';
export * from './api';
export * from './utils';
export * from './config';
