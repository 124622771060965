import { ApiError } from 'next/dist/server/api-utils';
import { logger } from '@finance/shared/dist/utils/logger';

export default class FinanceError extends ApiError {
    constructor(message: string, obj?: object, statusCode = 500) {
        super(statusCode, message);
        const logObject = { errorType: this.getErrorType(), error: this, ...obj };

        if (statusCode >= 400 && statusCode < 500) {
            logger.debug(logObject, message);
        } else {
            logger.error(logObject, message);
        }
    }

    public getErrorType() {
        return this.constructor.name;
    }

    private static isError(error: unknown): error is Error {
        return typeof error === 'object' && error !== null && 'message' in error;
    }

    public static isFinanceError(error: unknown): error is FinanceError {
        return this.isError(error) && 'statusCode' in error;
    }

    public static toFinanceError(error: unknown): FinanceError {
        if (this.isFinanceError(error)) {
            return error;
        }

        return new FinanceError(this.isError(error) ? (error as Error).message : JSON.stringify(error));
    }
}
