export enum AccountPurposeEnum {
    Primary = 'primary',
    Tax = 'tax',
    Savings = 'savings',
    LegacySavings = 'legacySavings',
    LegacyPrimary = 'legacyPrimary',
    LegacyTax = 'legacyTax'
}

export enum AccountStatusEnum {
    Open = 'Open',
    Frozen = 'Frozen',
    Closed = 'Closed'
}

export enum ApplicationStatusEnum {
    Approved = 'Approved',
    AwaitingDocuments = 'AwaitingDocuments',
    Canceled = 'Canceled',
    Denied = 'Denied',
    Pending = 'Pending',
    PendingReview = 'PendingReview'
}

export enum CustomerStatusEnum {
    Active = 'Active',
    Archived = 'Archived'
}

export enum CustomerTokenPurposeEnum {
    ReadCardDetails = 'ReadCardDetails',
    TransferMoney = 'TransferMoney',
    FullPrivileges = 'FullPrivileges'
}

export enum CustomerTypeEnum {
    Individual = 'individualCustomer',
    Business = 'businessCustomer'
}

export enum EntityTypeEnum {
    Corporation = 'Corporation',
    LLC = 'LLC',
    Partnership = 'Partnership',
    SoleProprietor = 'Sole proprietor'
}

export enum MongoCollectionsEnum {
    CUSTOMERS = 'customers',
    ACCOUNTS = 'accounts',
    EVENTS = 'events',
    TAX_ENTITIES = 'tax_entities',
    PLAID_INSTITUTION_AUTHS = 'plaid_institution_auths',
    GOAL = 'goal',
    EMPLOYER = 'employer'
}

export enum TransactionTypeEnum {
    BOOK = 'bookTransaction',
    CARD = 'cardTransaction',
    SIM = 'achPayment',
    PURCHASE = 'purchaseTransaction',
    FEE = 'feeTransaction',
    ATM = 'atmTransaction',
    ORIGINATED_ACH = 'originatedAchTransaction',
    RECEIVED_ACH = 'receivedAchTransaction',
    RETURNED_ACH = 'returnedAchTransaction',
    RETURNED_RECEIVED_ACH = 'returnedReceivedAchTransaction',
    HONEYBOOK_PAYMENT = 'honeybookPayment'
}

export enum TransactionFilterTypeEnum {
    ORIGINATED_ACH = 'OriginatedAch',
    RECEIVED_ACH = 'ReceivedAch',
    RETURNED_ACH = 'ReturnedAch',
    DISHONORED_ACH = 'DishonoredAch',
    BOOK = 'Book',
    PURCHASE = 'Purchase',
    ATM = 'Atm',
    FEE = 'Fee',
    REVERSAL = 'Reversal',
    CARD_TRANSACTION = 'CardTransaction',
    BATCH_RELEASE = 'BatchRelease',
    WIRE = 'Wire',
    DISPUTE = 'Dispute',
    ADJUSTMENT = 'Adjustment',
    INTEREST = 'Interest',
    REWARD = 'Reward',
    CHECK_DEPOSIT = 'CheckDeposit',
    RETURNED_CHECK_DEPOSIT = 'ReturnedCheckDeposit',
    PAYMENT_CANCELED = 'PaymentCanceled',
    CUSTOMER_REPAYMENT = 'CustomerRepayment'
}

export enum PaymentTypeEnum {
    ACH = 'achPayment',
    RECEIVED_ACH = 'achReceivedPayment',
    BOOK = 'bookPayment',
    WIRE = 'wirePayment',
    BILL = 'billPayment'
}

export enum UnitVerificationTokenChannelEnum {
    SMS = 'SMS',
    CALL = 'CALL'
}

export enum BusinessVertical {
    ADULT_ENTERTAINMENT_DATING_OR_ESCORT_SERVICES = 'AdultEntertainmentDatingOrEscortServices',
    AGRICULTURE_FORESTRY_FISHING_OR_HUNTING = 'AgricultureForestryFishingOrHunting',
    ARTS_ENTERTAINMENT_AND_RECREATION = 'ArtsEntertainmentAndRecreation',
    BUSINESS_SUPPORT_OR_BUILDING_SERVICES = 'BusinessSupportOrBuildingServices',
    CANNABIS = 'Cannabis',
    CONSTRUCTION = 'Construction',
    DIRECT_MARKETING_OR_TELEMARKETING = 'DirectMarketingOrTelemarketing',
    EDUCATIONAL_SERVICES = 'EducationalServices',
    FINANCIAL_SERVICES_CRYPTOCURRENCY = 'FinancialServicesCryptocurrency',
    FINANCIAL_SERVICES_DEBIT_COLLECTION_OR_CONSOLIDATION = 'FinancialServicesDebitCollectionOrConsolidation',
    FINANCIAL_SERVICES_MONEY_SERVICES_BUSINESS_OR_CURRENCY_EXCHANGE = 'FinancialServicesMoneyServicesBusinessOrCurrencyExchange',
    FINANCIAL_SERVICES_OTHER = 'FinancialServicesOther',
    FINANCIAL_SERVICES_PAYDAY_LENDING = 'FinancialServicesPaydayLending',
    GAMING_OR_GAMBLING = 'GamingOrGambling',
    HEALTH_CARE_AND_SOCIAL_ASSISTANCE = 'HealthCareAndSocialAssistance',
    HOSPITALITY_ACCOMMODATION_OR_FOOD_SERVICES = 'HospitalityAccommodationOrFoodServices',
    LEGAL_ACCOUNTING_CONSULTING_OR_COMPUTER_PROGRAMMING = 'LegalAccountingConsultingOrComputerProgramming',
    MANUFACTURING = 'Manufacturing',
    MINING = 'Mining',
    NUTRACEUTICALS = 'Nutraceuticals',
    PERSONAL_CARE_SERVICES = 'PersonalCareServices',
    PUBLIC_ADMINISTRATION = 'PublicAdministration',
    REAL_ESTATE = 'RealEstate',
    RELIGIOUS_CIVIC_AND_SOCIAL_ORGANIZATIONS = 'ReligiousCivicAndSocialOrganizations',
    REPAIR_AND_MAINTENANCE = 'RepairAndMaintenance',
    RETAIL_TRADE = 'RetailTrade',
    TECHNOLOGY_MEDIA_OR_TELECOM = 'TechnologyMediaOrTelecom',
    TRANSPORTATION_OR_WAREHOUSING = 'TransportationOrWarehousing',
    UTILITIES = 'Utilities'
}

enum ProhibitedBusinessVertical {
    ADULT_ENTERTAINMENT_DATING_OR_ESCORT_SERVICES = BusinessVertical.ADULT_ENTERTAINMENT_DATING_OR_ESCORT_SERVICES,
    CANNABIS = BusinessVertical.CANNABIS,
    DIRECT_MARKETING_OR_TELEMARKETING = BusinessVertical.DIRECT_MARKETING_OR_TELEMARKETING,
    FINANCIAL_SERVICES_CRYPTOCURRENCY = BusinessVertical.FINANCIAL_SERVICES_CRYPTOCURRENCY,
    FINANCIAL_SERVICES_DEBIT_COLLECTION_OR_CONSOLIDATION = BusinessVertical.FINANCIAL_SERVICES_DEBIT_COLLECTION_OR_CONSOLIDATION,
    FINANCIAL_SERVICES_MONEY_SERVICES_BUSINESS_OR_CURRENCY_EXCHANGE = BusinessVertical.FINANCIAL_SERVICES_MONEY_SERVICES_BUSINESS_OR_CURRENCY_EXCHANGE,
    FINANCIAL_SERVICES_PAYDAY_LENDING = BusinessVertical.FINANCIAL_SERVICES_PAYDAY_LENDING,
    GAMING_OR_GAMBLING = BusinessVertical.GAMING_OR_GAMBLING,
    MINING = BusinessVertical.MINING,
    NUTRACEUTICALS = BusinessVertical.NUTRACEUTICALS
}

const prohibitedBusinessVerticals = Object.values(ProhibitedBusinessVertical) as string[];
export const ALLOWED_BUSINESS_VERTICALS = Object.values(BusinessVertical).filter(v => !prohibitedBusinessVerticals.includes(v));

export enum ECategoryLegacy {
    CommissionAndFees = 'CommissionAndFees',
    Meals = 'Meals',
    AdvertisingAndMarketing = 'AdvertisingAndMarketing',
    Insurance = 'Insurance',
    ContractLabor = 'ContractLabor',
    OfficeExpense = 'OfficeExpense',
    RepairsAndMaintenance = 'RepairsAndMaintenance',
    Travel = 'Travel',
    ClientGifts = 'ClientGifts',
    Unsure = 'Unsure',
    Other = 'Other',
    Personal = 'Personal',
    Utilities = 'Utilities',
    LegalAndProfessionalFees = 'LegalAndProfessionalFees',
    Supplies = 'Supplies',
    Depreciation = 'Depriciation',
    EmployeeBenefitsProgram = 'EmployeeBenefitsProgram',
    InterestMortage = 'InterestMortage',
    InterestOther = 'InterestOther',
    RentVehiclesAndEquipment = 'RentVehiclesAndEquipment',
    RentBusinessProperty = 'RentBusinessProperty',
    TaxesAndLicenses = 'TaxesAndLicenses',
    Wages = 'Wages',
    VehiclePurchase = 'VehiclePurchase'
}

export enum ECategory {
    BANKING_AND_FINANCE = 'BankingAndFinance',
    CHARITY = 'Charity',
    EDUCATION = 'Education',
    ENTERTAINMENT = 'Entertainment',
    FOOD_AND_DRINK = 'FoodAndDrink',
    GOVERNMENT = 'Government',
    INDUSTRIAL_AND_ENERGY = 'IndustrialAndEnergy',
    ORGANIZATIONS = 'Organizations',
    PROFESSIONAL_SERVICES = 'ProfessionalServices',
    RELIGIOUS = 'Religious',
    RETAIL = 'Retail',
    SERVICES = 'Services',
    TECHNOLOGY = 'Technology',
    TRAVEL = 'Travel',
    UTILITIES = 'Utilities',
    PERSONAL = 'Personal',
    OTHER = 'Other'
}

export enum CategoriesTierTwo {
    ACCOUNTING_AND_BOOKKEEPING = 'AccountingAndBookkeeping',
    ATMS = 'Atms',
    BENEFITS = 'Benefits',
    BUSINESS_BROKERS_AND_FRANCHISES = 'BusinessBrokersAndFranchises',
    CREDIT_REPORTING = 'CreditReporting',
    FINANCIAL_PLANNING_AND_INVESTMENTS = 'FinancialPlanningAndInvestments',
    INSURANCE = 'Insurance',
    LENDING = 'Lending',
    ADULT_ENTERTAINMENT = 'AdultEntertainment',
    CASINOS_AND_GAMBLING = 'CasinosAndGambling',
    MEDIA = 'Media',
    RECREATION = 'Recreation',
    SOCIAL_CLUBS = 'SocialClubs',
    THEATER_AND_CINEMA = 'TheaterAndCinema',
    TICKETS_AND_RESERVATIONS = 'TicketsAndReservations',
    VENUES = 'Venues',
    BAKERIES = 'Bakeries',
    BARS = 'Bars',
    BREWERIES_DISTILLERIES_AND_WINERIES = 'BreweriesDistilleriesAndWineries',
    CAFES = 'Cafes',
    FOOD_DELIVERY_SERVICES = 'FoodDeliveryServices',
    RESTAURANTS = 'Restaurants',
    SPECIALTY_FOODS = 'SpecialtyFoods',
    PUBLIC_SERVICES = 'PublicServices',
    TAXES = 'Taxes',
    AGRICULTURE_AND_FORESTRY = 'AgricultureAndForestry',
    MANUFACTURING = 'Manufacturing',
    OIL_AND_GAS = 'OilAndGas',
    RENEWABLE_ENERGY = 'RenewableEnergy',
    BUSINESS_SERVICES = 'BusinessServices',
    LEGAL_SERVICES = 'LegalServices',
    ADULT_RETAIL = 'AdultRetail',
    ARTS_AND_HOBBIES = 'ArtsAndHobbies',
    AUTOMOTIVE_RETAIL = 'AutomotiveRetail',
    CLOTHING_AND_ACCESSORIES = 'ClothingAndAccessories',
    COMMERCIAL_SUPPLIES = 'CommercialSupplies',
    COMPUTERS_AND_ELECTRONICS = 'ComputersAndElectronics',
    GAS_STATIONS = 'GasStations',
    GENERAL_GOODS = 'GeneralGoods',
    HARDWARE_AND_HOME_IMPROVEMENT = 'HardwareAndHomeImprovement',
    NEWSSTANDS = 'Newsstands',
    OFFICE_SUPPLIES = 'OfficeSupplies',
    ONLINE_MARKETPLACE = 'OnlineMarketplace',
    OUTLETS = 'Outlets',
    PHARMACIES = 'Pharmacies',
    SPECIALTY_RETAIL = 'SpecialtyRetail',
    SUPERMARKETS_AND_GROCERY_STORES = 'SupermarketsAndGroceryStores',
    VENDING_MACHINE = 'VendingMachine',
    WAREHOUSES_AND_WHOLESALE_STORES = 'WarehousesAndWholesaleStores',
    AUTOMOTIVE_SERVICES = 'AutomotiveServices',
    BEAUTY_SPAS_AND_SALONS = 'BeautySpasAndSalons',
    CATERING = 'Catering',
    CLOTHING_SERVICES = 'ClothingServices',
    CONSTRUCTION_AND_HOME_IMPROVEMENT = 'ConstructionAndHomeImprovement',
    DELIVERY_SERVICES = 'DeliveryServices',
    EMPLOYMENT_SERVICES = 'EmploymentServices',
    EVENTS_AND_EVENT_PLANNING = 'EventsAndEventPlanning',
    FUNERAL_SERVICES = 'FuneralServices',
    GYMS_HEALTH_AND_FITNESS_CENTERS = 'GymsHealthAndFitnessCenters',
    HOUSEHOLD_SERVICES = 'HouseholdServices',
    MEDICAL_AND_HEALTHCARE_SERVICES = 'MedicalAndHealthcareServices',
    PET_SERVICES = 'PetServices',
    SECURITY_AND_SAFETY = 'SecurityAndSafety',
    SHIPPING_AND_FREIGHT = 'ShippingAndFreight',
    STORAGE = 'Storage',
    TOURIST_INFORMATION_AND_SERVICES = 'TouristInformationAndServices',
    WATER_AND_WASTE_MANAGEMENT_SERVICES = 'WaterAndWasteManagementServices',
    ADVERTISING_TECHNOLOGY = 'AdvertisingTechnology',
    AGRICULTURAL_TECHNOLOGY = 'AgriculturalTechnology',
    BIOTECHNOLOGY = 'Biotechnology',
    EDUCATIONAL_TECHNOLOGY = 'EducationalTechnology',
    ENVIRONMENTAL_TECHNOLOGY = 'EnvironmentalTechnology',
    FINANCIAL_TECHNOLOGY = 'FinancialTechnology',
    HARDWARE = 'Hardware',
    LEGAL_TECHNOLOGY = 'LegalTechnology',
    SECURITY_AND_PRIVACY = 'SecurityAndPrivacy',
    SOFTWARE = 'Software',
    WEB_INFRASTRUCTURE = 'WebInfrastructure',
    LANDMARKS = 'Landmarks',
    LODGING = 'Lodging',
    TRANSPORTATION = 'Transportation',
    TRAVEL_SERVICES = 'TravelServices',
    INTERNET = 'Internet',
    TELECOMMUNICATIONS = 'Telecommunications',
    DIGITAL_MONEY_MOVEMENT = 'DigitalMoneyMovement',
    FAST_FOOD = 'FastFood',
    RESTAURANT = 'Restaurant',
    GAS_STATION = 'GasStation'
}

export enum PlaidInstitutionAuthStatus {
    PendingData = 'PendingData',
    Ok = 'Ok',
    AboutToExpire = 'AboutToExpire',
    NeedRelogin = 'NeedRelogin',
    Deleted = 'Deleted'
}

export enum TaxCategoryEnum {
    AdvertisingAndMarketing = 'AdvertisingAndMarketing',
    GasAndFuel = 'GasAndFuel',
    TollsAndParking = 'TollsAndParking',
    CharitableContribution = 'CharitableContribution',
    ContractLabor = 'ContractLabor',
    Rent = 'Rent',
    OfficeExpenses = 'OfficeExpenses',
    OfficeSupplies = 'OfficeSupplies',
    Insurance = 'Insurance',
    Interest = 'Interest',
    LegalAndProfessionalServices = 'LegalAndProfessionalServices',
    TravelMeals = 'TravelMeals',
    MealsWithClients = 'MealsWithClients',
    Supplies = 'Supplies',
    Utilities = 'Utilities',
    ShippingAndPostage = 'ShippingAndPostage',
    ContinuingEducationAndTraining = 'ContinuingEducationAndTraining',
    BankAndTransactionFees = 'BankAndTransactionFees',
    ClientGifts = 'ClientGifts',
    HomeOfficeExpenses = 'HomeOfficeExpenses',
    TravelLodging = 'TravelLodging',
    TravelAirfare = 'TravelAirfare',
    Licenses = 'Licenses',
    PayrollWages = 'PayrollWages',
    PayrollTaxes = 'PayrollTaxes',
    Personal = 'Personal',
    CommissionAndFees = 'CommissionAndFees'
}

export enum TaxCategoryTourSteps {
    TaxCategoryIntroduction = 'TaxCategoryIntroduction',
    OldCategoryPlaceChange = 'OldCategoryPlaceChange',
    Done = 'Done'
}
