import { sharedConfig } from '@finance/shared';

const database = {
    dbName: process.env.MONGO_DB_NAME ?? 'finance',
    enabled: process.env.USE_DB === 'true'
};

const unit = {
    /**
     * `adminToken` is a strong admin token with more privileges to do specific administrative actions
     * - customers-read, customers-write
     * - accounts-read, accounts-write
     * - cards-read, cards-write
     * @since 18/05/2023
     */
    adminToken: '',
    applicationTokenDuration: Number(process.env.UNIT_APPLICATION_TOKEN_DURATION) || 0,
    baseURL: process.env.UNIT_BASE_URL || 'https://api.s.unit.sh',
    baseAppURL: sharedConfig.env.isProductionEnv ? 'https://app.unit.co' : 'https://app.s.unit.sh',
    ciToken: process.env.UNIT_CI_TOKEN || '',
    defaultStreamBatchSize: Number(process.env.DEFAULT_STREAM_BATCH_SIZE) || 64,
    token: (sharedConfig.env.isTestEnv ? process.env.UNIT_CI_TOKEN : process.env.UNIT_TOKEN) || '',
    demoToken: process.env.UNIT_DEMO_TOKEN || '',
    tokenExaminationInterval: 300000,
    tokenTTL: process.env.UNIT_TOKEN_TTL ? parseInt(process.env.UNIT_TOKEN_TTL) : 1800,
    userId: process.env.UNIT_USER_ID || '',
    webhookCacheTTL: Number(process.env.UNIT_WEBHOOK_CACHE_TTL) || 1800,
    webhookToken: process.env.UNIT_WEBHOOK_TOKEN || 'secret',
    depositProduct: process.env.UNIT_DEPOSIT_PRODUCT || 'checking',
    legacyDepositProduct: process.env.LEGACY_DEPOSIT_PRODUCT || 'checking',
    payoutsOrgAccountId: String(process.env.PAYOUTS_ORG_ACCOUNT_ID),
    newPayoutsOrgAccountId: String(process.env.LSBX_PAYOUTS_ORG_ACCOUNT_ID)
};
unit.adminToken = process.env.UNIT_ADMIN_TOKEN || unit.token || '';

const crypto = {
    algorithm: process.env.CRYPTO_ALGORITHM || 'aes-256-gcm',
    key: process.env.CRYPTO_KEY || 'key',
    iv: process.env.CRYPTO_IV || 'iv'
};

const plaid = {
    baseUrl: process.env.PLAID_BASE_URL || 'https://sandbox.plaid.com',
    clientId: process.env.PLAID_CLIENT_ID,
    secret: process.env.PLAID_SECRET,
    // replace with ngrok url for local development.
    webhookUrl: process.env.PLAID_WEBHOOK_URL || undefined
};

const envVarTemporalNamespaceRetention = parseInt(process.env.TEMPORAL_NAMESPACE_RETENTION || '');
const temporalNamespaceRetention: number = isNaN(envVarTemporalNamespaceRetention) ? 60 * 60 * 24 : envVarTemporalNamespaceRetention;
const temporal = {
    url: process.env.TEMPORAL_HOST || 'localhost:7233',
    namespaceRetention: temporalNamespaceRetention,
    tls: {
        publicKeyPath: process.env.TEMPORAL_CLOUD_PUBLIC_KEY_PATH || '/secrets/temporal.crt',
        privateKeyPath: process.env.TEMPORAL_CLOUD_PRIVATE_KEY_PATH || '/secrets/temporal.key'
    }
};

const kafka = {
    cert: process.env.KAFKA_CERT || '',
    caLocation: process.env.KAFKA_CA_LOCATION || '',
    enabled: process.env.KAFKA_ENABLED === 'true'
};

const datadog = { enabled: process.env.ENABLE_DATADOG === 'true' };

export const platformConfig = {
    crypto,
    database,
    unit,
    plaid,
    temporal,
    kafka,
    datadog
};

export default platformConfig;
