import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import sharedConfig from '../config/sharedConfig';
import logger from './logger';
import { ContextHeaderMismatchError } from '../errors/context-header-mismatch-error';
import { alsContext, AlxContextStore } from './als';
import { compact } from 'lodash';

export interface AxiosFactoryProps {
    config: AxiosRequestConfig;
    cacheTTL?: number;
    cacheAxios?: boolean;
}

export class AxiosFactory {
    static create({ config, cacheTTL, cacheAxios = false }: AxiosFactoryProps): AxiosInstance {
        // Create `axios` instance with pre-configured `axios-cache-interceptor`,
        // unless we are in test env
        let axiosInstance = axios.create(config);

        if (!sharedConfig.env.isTestEnv && cacheAxios) {
            axiosInstance = setupCache(axiosInstance, {
                ttl: cacheTTL ?? sharedConfig.dataSourceCacheTTL
            });
        }

        const store = alsContext?.getStore();

        const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
            logger.debug(
                {
                    request: serializedConfig(config)
                },
                '[HTTP] Request'
            );
            config.headers.set('x-request-id', store?.requestId);
            return config;
        };

        const onResponse = (response: AxiosResponse): AxiosResponse => {
            const { status, statusText, headers } = response;

            logger.debug(
                {
                    response: {
                        config: serializedConfig(response.config),
                        status,
                        statusText
                    }
                },
                '[HTTP] Response'
            );

            validateCompanyHeader({ headers, store });
            validateAccountHeader({ headers, store });

            return response;
        };

        const onError = (error: AxiosError) => {
            const { config, response } = error;

            logger.error(
                {
                    error: {
                        config: config && serializedConfig(config),
                        status: response?.status,
                        data: response?.data,
                        headers: response?.headers
                    }
                },
                '[HTTP] Error'
            );

            return Promise.reject(error);
        };

        axiosInstance.interceptors.request.use(onRequest);
        axiosInstance.interceptors.response.use(onResponse, onError);

        return axiosInstance;
    }
}

export default AxiosFactory;

function serializedConfig(config: AxiosRequestConfig) {
    const { baseURL, method, url, data, params } = config;

    return {
        baseURL,
        url,
        method,
        params,
        config: {
            data: data ? Buffer.from(JSON.stringify(data)).toString('base64') : [],
            path: data?.request?.path,
            pathname: data?.request?.pathname,
            search: data?.request?.search
        }
    };
}

type TVerifyHeadersParams = {
    headers: AxiosResponse['headers'];
    store?: AlxContextStore;
};

function validateCompanyHeader({ headers, store }: TVerifyHeadersParams) {
    validateResponseHeaders(headers, 'x-hb-request-company-id', [store?.ctx?.ctxc, store?.ctx?.ctxac]);
}

function validateAccountHeader({ headers, store }: TVerifyHeadersParams) {
    validateResponseHeaders(headers, 'x-hb-request-account-id', [store?.ctx?.ctxu, store?.ctx?.ctxa]);
}

function validateResponseHeaders(
    headers: AxiosResponse['headers'],
    headerKey: 'x-hb-request-company-id' | 'x-hb-request-account-id',
    values: (string | undefined)[]
) {
    const headerValue = headers[headerKey];
    const compactedValues = compact(values);
    if (!!headerValue && compactedValues.length && !compactedValues.includes(headerValue)) {
        throw new ContextHeaderMismatchError(headerKey, headerValue, compactedValues);
    }
}
