import { FintechEmployerDataService } from '../types/dal-types';
import { EmployerModel, EmployerModelDTO } from '../dal/models/employer';
import { ObjectId } from 'bson';

export class EmployerDataService {
    private dataService: FintechEmployerDataService<EmployerModel>;

    constructor({ dataService }: { dataService: FintechEmployerDataService<EmployerModel> }) {
        this.dataService = dataService;
    }

    async findByHbUserId(hbUserId: string) {
        return this.dataService.findByHbUserId(hbUserId);
    }

    async create(employer: EmployerModelDTO) {
        return this.dataService.create({
            hbUserId: new ObjectId(employer.hbUserId),
            salsaId: employer.salsaId
        });
    }

    async get(employerId: string) {
        return this.dataService.read(new ObjectId(employerId));
    }
}
