import pino from 'pino';
import { pinoHttp } from 'pino-http';
import logger from './logger';
import alsContext from './als';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function baseReqResSerializer(reqOrRes: any) {
    const { id, method, url, headers, remoteAddress, remotePort, query, user } = reqOrRes;
    return {
        id,
        method,
        url,
        headers: { ...headers },
        remoteAddress,
        remotePort,
        query,
        user
    };
}

const isClientError = (statusCode: number) => statusCode >= 400 && statusCode < 500;

class UnitErrorType extends Error {
    readonly isUnitError = true;
    readonly underlying: Error;
    readonly errors: { status: string }[];

    constructor(message: string, underlying?: Error, errors?: { status: string }[]) {
        super(message);
        this.underlying = underlying || new Error(message);
        this.errors = errors || [];
    }
}

export const httpTracer = pinoHttp({
    logger,
    genReqId: function (_res) {
        // FSR the req contextual logger is not being applied here,
        // this is a workaround that makes the requestId to appear in the log
        return alsContext?.getStore()?.requestId || 'not-yet';
    },
    customLogLevel: function (res, err) {
        let errStatusCode = 0;
        if ((err as UnitErrorType)?.errors?.length) {
            errStatusCode = Number((err as UnitErrorType).errors[0].status);
        }

        if (isClientError(res.statusCode) || isClientError(errStatusCode)) {
            return 'debug';
        } else if (res.statusCode >= 500 || err) {
            return 'error';
        } else if (res.statusCode >= 300 && res.statusCode < 400) {
            return 'silent';
        }

        return 'trace';
    },
    customSuccessMessage: function (res) {
        if (res.statusCode === 404) {
            return 'resource not found';
        }

        return 'request completed';
    },
    customReceivedMessage: function (req) {
        return 'request received: ' + req.method;
    },
    customErrorMessage: function (error, res) {
        return 'request errored with status code: ' + res.statusCode;
    },
    serializers: {
        err: pino.stdSerializers.err,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        req: function (req: any) {
            const log = baseReqResSerializer(req);
            log.headers.cookie = log.headers.cookie ? 'TRUNCATED' : 'NONE';
            return log;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        res: function (res: any) {
            return baseReqResSerializer(res);
        }
    },
    wrapSerializers: true
});
