export enum LocaleNS {
    application = 'application',
    buckets = 'buckets',
    common = 'common',
    dashboard = 'dashboard',
    widgets = 'widgets',
    expense = 'expense',
    internalServerError = '500',
    kyc = 'kyc',
    newKyc = 'newKyc',
    notFound = '404',
    signin = 'signin',
    setupList = 'setupList',
    smartTax = 'smartTax',
    smartTaxWithSavings = 'smartTaxWithSavings',
    taxWithholding = 'taxWithholding',
    taxWithholdingWithSavings = 'taxWithholdingWithSavings',
    transferMoney = 'transferMoney',
    twoFactor = 'twoFactor',
    userDocs = 'userDocs',
    loader = 'loader',
    welcome = 'welcome',
    whitelist = 'whitelist',
    newWelcome = 'newWelcome',
    documents = 'documents',
    cashflowWidget = 'cashflowWidget',
    newCashflowWidget = 'newCashflowWidget',
    overview = 'overview',
    transactionFeedWidget = 'transactionFeedWidget',
    hbPaymentsWidget = 'hbPaymentsWidget',
    snapshotCards = 'snapshotCards',
    forbidden = '403',
    yearlyRevenueGoal = 'yearlyRevenueGoal',
    taxes = 'taxes',
    taxCategorization = 'taxCategorization',
    financialInsights = 'financialInsights',
    payroll = 'payroll'
}
